import React from "react";
import InfiniteCollection from "@pageContent/common/InfiniteCollection";
import { useHostConfig } from "@utils/common/getHostConfig";
import useFetchLimit from "@utils/pageContent/useFetchLimit";

const collectionName = "just-in-collection";

const defaultArticleGrid = {
  itemFetchLimit: {
    xs: 8,
    sm: 8,
    md: 9,
    lg: 8,
  },
};

const JustInCollection: React.FC<CategoryBasedCollection> = (props) => {
  const { designTokens } = useHostConfig();

  const articlesGrid = designTokens?.articleGrid?.itemFetchLimit || defaultArticleGrid;

  const limit = useFetchLimit(articlesGrid);

  return <InfiniteCollection {...props} limit={limit} collectionName={collectionName} />;
};

export default JustInCollection;
