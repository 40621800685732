import dynamic from "next/dynamic";
import { SxProps, Theme } from "@mui/material";
import { OutletNameEnum } from "@constants/consts";
import { useHostConfig } from "@utils/common/getHostConfig";

export type StyleFunction<T extends string = string> = (theme: Theme) => Record<T, SxProps<Theme>>;

const styleMap: Partial<Record<OutletNameEnum, React.ComponentType>> = {
  [OutletNameEnum.SKYRADIO]: dynamic(() => import("./config/skyradio.styles")),
  [OutletNameEnum.SHOWNIEUWS]: dynamic(() => import("./config/dailies.styles")),
  [OutletNameEnum.VANDAAGINSIDE]: dynamic(() => import("./config/dailies.styles")),
  [OutletNameEnum.HARTVANNEDERLAND]: dynamic(() => import("./config/dailies.styles")),
  [OutletNameEnum.RADIO10]: dynamic(() => import("./config/radio10.styles")),
  [OutletNameEnum.RADIO538]: dynamic(() => import("./config/radio538.styles")),
};

const OutletStyles = () => {
  const { outletName } = useHostConfig();

  const Styles = styleMap[outletName as OutletNameEnum];

  if (Styles) return <Styles />;

  return null;
};

export default OutletStyles;
