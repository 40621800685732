import React, { useEffect } from "react";
import getConfig from "next/config";
import Tealium, { HeartBeat, JwMonitor } from "@4tn/webx-analytics";
import { useQuery } from "@tanstack/react-query";
import { AvailabilityZone, CountryCode, QueryParams } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import detectAdBlock from "@utils/common/detectAdBlock";
import featureTooling, { FeatureSlugs, useFeature } from "@utils/common/featureTooling";
import getHostConfig from "@utils/common/getHostConfig";
import { observe, unobserve } from "@utils/common/intersectionObserver";
import {
  customInteraction,
  noticeError,
  noticeVideoError,
  setCustomAttributes,
  useNoticeError,
} from "@utils/common/newRelic";
import addControlButtons from "@utils/video/addControlButtons";
import addDrm from "@utils/video/addDrm";
import { getCustomFreeWheelConfig, getFreeWheelConfig } from "@utils/video/advertising";
import { filterBitrateOptions } from "@utils/video/filterBitrateOptions";
import getMute from "@utils/video/getMute";
import secondsToISODuration from "@utils/video/secondsToISODuration";
import { fetchVideo } from "@utils/video/videoApi";
import CustomVideoError, { CustomErrorType } from "./CustomVideoError";
import * as Styled from "./VideoPlayer.styled";

export const videoPlayerLoadingSkeletonTestId = "video-player-loading-skeleton";

const { publicRuntimeConfig } = getConfig();

const heartBeatScheme = [{ start: 0, interval: 300 }];

interface VideoPlayerProps {
  guid: string;
  image?: string;
  isFullWidthXs?: boolean;
  enableFloating?: boolean;
  onRemove?: () => void;
}

const VideoPlayerWrapper: React.FC<VideoPlayerProps> = (props) => {
  const { guid } = props;
  const videoQueryKey = ["video", guid];

  const { enabled: adBlockDetectionEnabled } = useFeature(FeatureSlugs.ADBLOCK_DETECTION);
  const [adBlockDetected, setAdBlockDetected] = React.useState(false);
  const {
    data: video,
    isFetching,
    error,
  } = useQuery<Program | null>({
    queryKey: videoQueryKey,
    queryFn: () => fetchVideo(guid),
    refetchOnReconnect: false,
  });

  useNoticeError(error, { queryKey: videoQueryKey.join(","), guid });

  useEffect(() => {
    let isOnline = true;
    function onOnline() {
      isOnline = true;
    }
    function onOffline() {
      isOnline = false;
    }

    function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async function detectAdBlockConsistently() {
      const initialDetected = await detectAdBlock();
      if (!initialDetected) return false;
      // Adding a delay before the second detection call
      await sleep(100);
      // Retry AdBlock detection to ensure accuracy
      const finalDetected = await detectAdBlock();
      return initialDetected && finalDetected;
    }

    detectAdBlockConsistently().then((detected) => {
      if (!isOnline) return console.log("User seems to be offline");

      Tealium.setDataLayer({ adblock: detected });
      if (detected && adBlockDetectionEnabled) setAdBlockDetected(true);
    });

    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);
    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
    };
  }, [adBlockDetectionEnabled]);

  if (video && !video.sources.length) return <CustomVideoError errorType={CustomErrorType.NOT_AVAILABLE} />;
  if (adBlockDetected) return <CustomVideoError errorType={CustomErrorType.AD_BLOCK_DETECTED} />;

  if (isFetching)
    return (
      <Styled.VideoSkeleton data-testid={videoPlayerLoadingSkeletonTestId} variant="rectangular" component="div" />
    );

  if (!video || video.guid !== props.guid) return <CustomVideoError errorType={CustomErrorType.NOT_FOUND} />;

  return <VideoPlayer {...props} video={video} />;
};

const VideoPlayer: React.FC<VideoPlayerProps & { video: Program }> = ({
  video,
  image,
  isFullWidthXs,
  enableFloating,
  onRemove,
}) => {
  const playerRef = React.useRef<HTMLDivElement>(null);
  const [customError, setCustomError] = React.useState<CustomErrorType | false>(false);

  const { guid } = video;

  React.useEffect(() => {
    if (!playerRef.current) return;

    let playerInstance: jwInstance;
    const observedContainer = enableFloating && playerRef.current.parentElement;

    const { jwApiKey, cloudinaryDomain, jwSkinName, jwSkinFile, jwVersion } = publicRuntimeConfig;
    const imageUrl =
      image && `${cloudinaryDomain}/image/fetch/ar_16:9,c_fill,dpr_2.0,f_auto,g_face:auto,h_235,w_auto,f_auto/${image}`;

    const handleRemoveFloatingPlayer = () => {
      const jwContainer = playerInstance.getContainer();
      if (jwContainer) {
        jwContainer.classList.remove("custom-floating");
      }

      playerInstance.pause();
    };

    try {
      const isMuted = getMute();
      const autostart = !document.location.search.includes(`${QueryParams.SEARCH_KEYWORD}=`) ? "viewable" : false;

      playerInstance = window.jwplayer(playerRef.current);
      playerInstance.setup({
        playlist: [{ ...video, image: imageUrl }],
        displaytitle: false,
        autostart,
        mute: isMuted,
        width: "100%",
        height: "100%",
        controls: true,
        key: jwApiKey,
        advertising: getFreeWheelConfig(),
        floating: false,
        intl: {
          nl: {
            nextUp: "Volgende",
            advertising: {
              cuetext: "Advertentie",
              podmessage: "__AD_POD_CURRENT__ van __AD_POD_LENGTH__.",
              loadingAd: "Advertentie laden",
              admessage: "De advertentie eindigt over xx seconden",
            },
          },
        },
        skin: {
          name: jwSkinName,
          url: jwSkinFile,
          timeslider: {
            progress: colors.primary,
            rail: "rgba(255,255,255,0.6)",
          },
        },
      });
      const {
        advertising: { zoneGroup: playerChannel },
      } = getHostConfig();

      playerInstance.on("ready", () => {
        customInteraction("Player Ready");
        playerInstance.addButton("", "Sluiten", handleRemoveFloatingPlayer, "floating-close-button");
      });

      playerInstance.on("playlistItem", () => {
        addControlButtons(playerInstance);
      });

      playerInstance.setPlaylistItemCallback(async (item) => {
        item.sources = item.sources.map(addDrm);
        item.freewheel = await getCustomFreeWheelConfig(item);
        item.fwassetid = item.guid;
        return item;
      });

      const heartBeatCallback = (data: { currentHeartBeat: number }) => {
        customInteraction(`Milestone ${secondsToISODuration(data.currentHeartBeat)}`);
        return false;
      };

      const startHeartBeat = (): void => {
        if (HeartBeat.state === "idle") HeartBeat.start();
      };

      HeartBeat.init({
        scheme: heartBeatScheme,
        events: { onHeartBeat: heartBeatCallback },
      });

      // When using muted playback after each ad the player will switch back to muted even when the user has manually unmuted the player
      let isSyncingMuteState = isMuted;
      let shouldMute = isMuted;
      playerInstance.on("mute", ({ mute }) => {
        shouldMute = mute;
      });
      const ensureMuteState = () => {
        if (isSyncingMuteState) playerInstance.setMute(shouldMute);
      };
      playerInstance.on("adImpression", ensureMuteState);

      playerInstance.on("firstFrame", () => {
        playerInstance.setConfig({ autostart: false });
        customInteraction("Stream start");
        startHeartBeat();
        ensureMuteState();
      });
      playerInstance.on("beforePlay", () => {
        window.eventBus.dispatch("activeVideo", { video: { guid: video.guid } });
      });
      playerInstance.on("levels", filterBitrateOptions);
      playerInstance.on("play", () => startHeartBeat());
      playerInstance.on("pause", () => HeartBeat.stop());
      playerInstance.on("idle", () => HeartBeat.stop());

      setCustomAttributes({ media_id: video.guid || "" });

      playerInstance.on("adComplete", () => {
        customInteraction("Ad Watched");
      });

      playerInstance.on("adError", () => {
        customInteraction("Failed Ad");
      });

      playerInstance.on("error", (error) => {
        const video = playerInstance.getPlaylistItem();
        noticeVideoError(error, video);
        const { countryCode: userCountry } = featureTooling.attributes;
        if (userCountry && userCountry !== CountryCode.NL && video?.availableRegion !== AvailabilityZone.WW) {
          setCustomError(CustomErrorType.GEO_ERROR);
        }
        HeartBeat.stop();
      });

      const useAdobeDelay = featureTooling.isEnabled(FeatureSlugs.ADOBE_DELAY);
      const convivaConfig = featureTooling.getVariables<{ apiKey: string; logLevel?: number }>(
        FeatureSlugs.CONVIVA_POC
      );
      JwMonitor.init({
        id: playerInstance.id,
        useAdobeDelay,
        convivaConfig: convivaConfig || undefined,
        defaultLayer: {
          player_version: jwVersion,
          player_channel: playerChannel,
          player_channelid: playerChannel,
          player_name: "webx_jwplayer",
        },
        events: {
          playlist: (): boolean => false,
          playlistComplete: (): boolean => {
            if (onRemove) {
              playerInstance.remove();
              onRemove();
              return false;
            }
            playerInstance.stop();
            return false;
          },
          mute: (): boolean => {
            return playerInstance.getState() !== "idle";
          },
          autostartNotAllowed: (): boolean => {
            if (featureTooling.isEnabled(FeatureSlugs.MUTED_AUTOPLAY)) {
              isSyncingMuteState = true;
              playerInstance.setMute(true);
              customInteraction("Muted autostart");
              JwMonitor.setPlayReason("autostart");
              playerInstance.play();
            }
            return false;
          },
        },
      });

      const handleIntersection = ({ isVisible }: { isVisible: boolean }) => {
        if (playerInstance) {
          const state = playerInstance.getState();
          const jwContainer = playerInstance.getContainer();
          if (state === "paused" && !jwContainer?.classList.contains("custom-floating")) {
            return;
          }
          jwContainer?.classList.toggle("custom-floating", !isVisible);
          jwContainer?.classList.toggle("in-app", featureTooling.attributes.inApp as boolean);
        }
      };

      if (observedContainer) {
        observe(observedContainer, handleIntersection);
      }
    } catch (error) {
      noticeError(error as Error, { guid });
    }
    function onActiveVideo(event: {
      video: {
        guid: string;
      } | null;
    }) {
      if (event.video?.guid !== video?.guid) {
        playerInstance.stop();

        if (onRemove) {
          playerInstance.remove();
          onRemove();
        }
      }
    }
    window.eventBus.on("activeVideo", onActiveVideo);

    return () => {
      HeartBeat.stop();
      window.eventBus.off("activeVideo", onActiveVideo);
      setCustomAttributes({ media_id: "" });
      observedContainer && unobserve(observedContainer);
      try {
        playerInstance.remove();
      } catch (ignore) {}
    };
    // we should not be adding new dependencies to the array so we avoid player instance recreation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerRef]);

  if (customError) {
    return <CustomVideoError errorType={customError} />;
  }

  return (
    <Styled.PlayerContainer aspectRatio="16:9" isFullWidthXs={!!isFullWidthXs}>
      <div ref={playerRef} />
    </Styled.PlayerContainer>
  );
};
export default VideoPlayerWrapper;
