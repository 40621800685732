import TritonAudioPlayer from "./TritonAudioPlayer";
import WebAudioPlayer from "./WebAudioPlayer";

class AudioPlayerManager {
  private webPlayer: WebAudioPlayer;
  private tritonPlayer: TritonAudioPlayer;
  private player: WebAudioPlayer | null = null;
  private eventCallbacks: PlayerEventRegistry<keyof AudioPlayerEventParams> = {};

  constructor() {
    this.webPlayer = new WebAudioPlayer(this.dispatch);
    this.tritonPlayer = new TritonAudioPlayer(this.dispatch);
  }

  async start(source: string): Promise<void> {
    if (this.player) {
      await this.player.stop();
    }
    this.setupPlayer(source);
    this.dispatch("beforePlay", { details: "Attempting to play..." });
    this.player?.play(source);
  }

  private setupPlayer(source: string): void {
    if (source.endsWith(".mp3") || source.endsWith(".aac")) {
      this.player = this.webPlayer;
      this.dispatch("playerType", "web");
    } else {
      this.player = this.tritonPlayer;
      this.dispatch("playerType", "triton");
    }
  }

  private dispatch = <K extends keyof AudioPlayerEventParams>(key: K, params: AudioPlayerEventParams[K]): void => {
    const callbacks = (this.eventCallbacks[key] as ((data: AudioPlayerEventParams[K]) => void)[]) || [];

    callbacks.forEach((callback) => {
      callback(params);
    });
  };

  on<K extends keyof AudioPlayerEventParams>(key: K, callback: (params: AudioPlayerEventParams[K]) => void): void {
    if (!this.eventCallbacks[key]) {
      this.eventCallbacks[key] = [];
    }
    const callbacks = this.eventCallbacks[key] as ((data: AudioPlayerEventParams[K]) => void)[];
    if (!callbacks.includes(callback)) {
      callbacks.push(callback);
    }
  }

  off<K extends keyof AudioPlayerEventParams>(key: K, callback: (params: AudioPlayerEventParams[K]) => void): void {
    const callbacks = this.eventCallbacks[key] as ((data: AudioPlayerEventParams[K]) => void)[];
    const index = callbacks?.indexOf(callback);
    if (callbacks && index && index !== -1) {
      callbacks.splice(index, 1);
    }
  }

  pause(): void {
    if (this.player) {
      this.player.pause();
    }
  }

  resume(): void {
    if (this.player) {
      this.player.resume();
    }
  }

  stop(): void {
    if (this.player) {
      this.player.stop();
    }
  }

  mute(): void {
    if (this.player) {
      this.player.mute();
    }
  }

  unmute(): void {
    if (this.player) {
      this.player.unmute();
    }
  }

  volumeChange(volume: number): void {
    if (this.player) {
      this.player.volumeChange(volume);
    }
  }
}

const audioPlayerManager = new AudioPlayerManager();

export default audioPlayerManager;
