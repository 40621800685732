import { useCallback } from "react";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { IconEnum } from "@constants/consts";
import { colors } from "@constants/cssVariables";
import { Station } from "@utils/audio/dataModels";
import audioPlayerManager from "../../AudioPlayerManager/AudioPlayerManager";
import * as Styled from "./MainPlayerControls.styled";

const MainPlayerControls: React.FC<{
  playerType: AudioPlayerEventParams["playerType"];
  mountSource: Station["mountSource"];
  playbackState: AudioPlayerState;
}> = ({ playerType, mountSource, playbackState }) => {
  const handlePlayIconClicked = useCallback(() => {
    if (playbackState === "stop" && mountSource) {
      window.eventBus.dispatch("audioSourceChange", { source: mountSource });
    } else if (playbackState === "pause") {
      audioPlayerManager.resume();
    }
  }, [playbackState, mountSource]);

  return (
    <Styled.MainPlayerControlsContainer>
      {playbackState === "playing" && playerType === "web" && (
        <Styled.StyledIconButton
          icon={IconEnum.PAUSE_THICK}
          size={BUTTON_SIZE.LARGE}
          variant={VARIANT.SECONDARY}
          color={colors.neutral0}
          onClick={() => audioPlayerManager.pause()}
          aria-label="pause"
        />
      )}
      {playbackState === "playing" && playerType === "triton" && (
        <Styled.StyledIconButton
          icon={IconEnum.STOP_FILLED}
          size={BUTTON_SIZE.LARGE}
          variant={VARIANT.SECONDARY}
          color={colors.neutral0}
          onClick={() => audioPlayerManager.stop()}
          aria-label="stop"
        />
      )}
      {(playbackState === "pause" || playbackState === "stop" || playbackState === "loading") && (
        <Styled.StyledIconButton
          icon={playbackState === "loading" ? IconEnum.SPINNER : IconEnum.PLAY_ICON_FILLED}
          size={BUTTON_SIZE.LARGE}
          variant={VARIANT.SECONDARY}
          color={colors.neutral0}
          onClick={handlePlayIconClicked}
          aria-label="play"
        />
      )}
    </Styled.MainPlayerControlsContainer>
  );
};

export default MainPlayerControls;
