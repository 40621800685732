import { useCallback, useEffect, useState } from "react";
import audioPlayerManager from "../../AudioPlayerManager/AudioPlayerManager";
import * as Styled from "./IconSection.styled";

export const volumeIconDataTestId = "volumeIconDataTestId";
export const volumeMutedIconDataTestId = "volumeMutedIconDataTestId";
export const volumeBarDataTestId = "volumeBarDataTestId";

const IconSection = () => {
  const [currentVolume, setCurrentVolume] = useState({ volume: 0.5 });
  const [preMuteVolume, setPreMuteVolume] = useState(1);

  useEffect(() => {
    audioPlayerManager.on("playerVolume", setCurrentVolume);
    return () => {
      audioPlayerManager.off("playerVolume", setCurrentVolume);
    };
  }, []);

  const handleVolumeMute = useCallback(() => {
    if (currentVolume.volume) {
      setPreMuteVolume(currentVolume.volume);
      setCurrentVolume({ volume: 0 });
      audioPlayerManager.mute();
      return;
    }
  }, [currentVolume.volume]);

  return (
    <Styled.IconSectionContainer>
      <Styled.StyledVideoIcon />
      <Styled.StyledWhatsAppIcon />
      <Styled.VolumeControlsContainer>
        {!!currentVolume.volume && <Styled.VolumeIcon onClick={handleVolumeMute} data-testid={volumeIconDataTestId} />}
        <Styled.VolumeMutedIcon
          onClick={() => audioPlayerManager.volumeChange(preMuteVolume)}
          isVolumeMuted={!currentVolume.volume}
          data-testid={volumeMutedIconDataTestId}
        />
        <Styled.VolumeBar
          type="range"
          min={0}
          max={1}
          step={0.01}
          value={currentVolume.volume}
          volumePercentage={`${(currentVolume.volume * 100).toString()}%`}
          onChange={(event) => audioPlayerManager.volumeChange(Number(event.target.value))}
          data-testid={volumeBarDataTestId}
        />
      </Styled.VolumeControlsContainer>
      <Styled.StyledPopOutIcon />
    </Styled.IconSectionContainer>
  );
};

export default IconSection;
