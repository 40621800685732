export default function createScriptElement(scriptSrc: string, document: Document = window.document): Promise<void> {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.onload = () => resolve();
    script.onerror = () => reject(`Error loading script '${scriptSrc}'`);
    script.async = true;
    script.src = scriptSrc;
    document.getElementsByTagName("head")[0].appendChild(script);
  });
}
