import { Skeleton, styled } from "@mui/material";
import AspectRatioContainer from "@common/AspectRatioContainer";
import { colors } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const PlayerContainer = styled(AspectRatioContainer, { shouldForwardProp: (prop) => prop !== "isFullWidthXs" })<{
  isFullWidthXs: boolean;
}>(({ theme, isFullWidthXs }) => ({
  backgroundColor: colors.neutral10,
  borderRadius: isFullWidthXs ? 0 : 8,
  "& > div": {
    position: "absolute",
    borderRadius: isFullWidthXs ? 0 : 8,
  },
  [theme.breakpoints.up("md")]: {
    borderRadius: 8,
    "& > div": {
      borderRadius: 8,
    },
  },
  "& .jwplayer.jw-state-error, & .jw-error": {
    position: "absolute !important",
    "& .jw-error-msg": {
      zIndex: zIndex.floating + 1,
    },
  },
  "& .jwplayer.jw-state-playing": {
    borderRadius: 0,
  },
}));

export const VideoSkeleton = styled(Skeleton)<{ component?: string }>({
  aspectRatio: "16 / 9",
  borderRadius: 2,
  width: "100%",
  height: "auto",
});
