import Image from "next/image";
import { styled } from "@mui/material";
import { Button } from "@common/Buttons";
import { colors, fonts } from "@constants/cssVariables";

export const NowPlayingContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  maxWidth: "80%",
  [theme.breakpoints.up("sm")]: {
    gap: theme.spacing(4),
    maxWidth: "45%",
  },
  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(4),
  },
}));

export const NowPlayingImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  minWidth: 48,
  height: 48,
  [theme.breakpoints.up("md")]: {
    minWidth: 96,
    height: 96,
  },
}));

export const NowPlayingImage = styled(Image)(({ theme }) => ({
  objectFit: "cover",
  borderRadius: 6,
  [theme.breakpoints.up("md")]: {
    borderRadius: 0,
  },
}));

export const NowPlayingTextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(1),
  },
}));

export const NowPlayingTitle = styled("p")(() => ({
  font: fonts.header4,
  color: colors.neutral0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));

export const NowPlayingSecondaryRowContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "baseline",
}));

export const NowPlayingLiveLabel = styled(Button)(({ theme }) => ({
  font: fonts.buttonSmall,
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

export const NowPlayingSecondaryTitle = styled("div")(() => ({
  font: fonts.bodySmall,
  color: colors.neutral0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 1,
}));
