import { styled } from "@mui/material";
import { ButtonContainer } from "@common/Buttons";
import { colors, fonts } from "@constants/cssVariables";

export const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(6),
  minHeight: 341,
  padding: theme.spacing(8, 4, 6),
  backgroundColor: colors.neutral10,
  borderBottom: "none",
  marginBottom: 0,
  borderRadius: 8,

  [`${TitleSubtitleContainer}`]: {
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "100%",
    },

    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },

  [`${Title}`]: {
    color: colors.neutral90,
  },

  [`${Subtitle}`]: {
    color: colors.neutral80,
  },

  [`${InputAndButtonContainer}`]: {
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },

    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },
  },

  [theme.breakpoints.up("md")]: {
    alignItems: "center",
    gap: theme.spacing(10),
    minHeight: 300,
    padding: theme.spacing(12, 16, 10),
  },

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(12, 10),
  },
}));

export const TitleSubtitleContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  fontWeight: "normal",

  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(4),
  },
}));

export const Title = styled("div")(({ theme }) => ({
  font: fonts.header2,

  [theme.breakpoints.up("md")]: {
    textAlign: "center",
  },
}));

export const Subtitle = styled("div")(({ theme }) => ({
  font: fonts.bodyMedium,

  [theme.breakpoints.up("md")]: {
    textAlign: "center",
  },
}));

export const InputAndButtonContainer = styled("form")(({ theme }) => ({
  display: "grid",
  gap: theme.spacing(6),
  width: "100%",
  gridTemplateRows: "3fr",
  gridTemplateColumns: "1fr",

  [`${ButtonContainer}`]: {
    width: "100%",
  },

  [`${ButtonContainer}:disabled`]: {
    // We want the disabled button to also have primary color background because of the spinner
    backgroundColor: `${colors.primary}`,
  },

  svg: {
    marginTop: theme.spacing(0.5),
  },

  [theme.breakpoints.up("md")]: {
    gridTemplateRows: "auto",
    gridTemplateAreas: `
      "input button" 
      "error error"`,
    gap: 0,

    [`${Input}`]: {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      gridArea: "input",
    },

    [`${ButtonContainer}`]: {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      width: 140,
      gridArea: "button",
    },
  },
}));

export const Input = styled("input", { shouldForwardProp: (prop) => prop !== "hasError" })<{
  hasError: boolean;
}>(({ theme, hasError }) => ({
  width: "100%",
  padding: theme.spacing(3.5, 6),
  borderRadius: 26,
  background: colors.neutral0,
  outline: "none",
  height: 52,
  font: fonts.bodyMedium,
  color: colors.neutral100,
  border: hasError ? `2px solid ${colors.danger100}` : "none",

  "::placeholder": {
    color: colors.neutral40,
    font: fonts.bodyMedium,
    fontWeight: "normal",
    opacity: 1,
  },
}));

export const ErrorText = styled("div")(({ theme }) => ({
  color: colors.danger100,
  font: fonts.bodySmall,
  textAlign: "center",
  marginTop: theme.spacing(-4),

  [theme.breakpoints.up("md")]: {
    marginTop: theme.spacing(2.5),
    gridArea: "error",
  },
}));

export const ConfirmedEmail = styled("span")(() => ({
  color: colors.primary,
}));

export const LegalText = styled("div")(({ theme }) => ({
  font: fonts.bodyExtraSmall,
  color: colors.neutral50,
  marginTop: theme.spacing(2),

  a: {
    color: colors.primary,
    cursor: "pointer",
  },

  "p + p": {
    marginTop: theme.spacing(3),
  },

  [theme.breakpoints.up("md")]: {
    width: 406,
    textAlign: "center",
    marginTop: theme.spacing(-2),
  },
}));
