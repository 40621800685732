import { styled } from "@mui/material";
import { PopoutIcon, VideoIcon, VolumeMinIcon, VolumeOffIcon, WhatsAppIcon } from "@common/Icons";
import { colors } from "@constants/cssVariables";

export const IconSectionContainer = styled("div")(({ theme }) => ({
  position: "relative",
  top: 12, // We are offseting the icon hover pseudo elements in order to keep the icons centered on the y-axis
  display: "none",
  gap: theme.spacing(6),
  [theme.breakpoints.up("sm")]: {
    display: "flex",
  },
}));

const sharedIconStyles = {
  cursor: "pointer",
  svg: {
    display: "flex",
    width: 24,
    height: 24,
    color: colors.neutral0,
  },
};

const sharedPseudoIconElementStyles = {
  "&:after": {
    content: "'•'",
    position: "relative",
    top: -5,
    left: 10,
    color: "transparent",
    fontFamily: "Tahoma", // So we can have uniform font-family styles across all outlets
    fontSize: 12,
  },
  "&:hover": {
    "&:after": {
      color: colors.neutral0,
    },
  },
};

export const StyledVideoIcon = styled(VideoIcon)(() => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
}));

export const StyledWhatsAppIcon = styled(WhatsAppIcon)(() => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
}));

export const VolumeIcon = styled(VolumeMinIcon)(() => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
}));

export const VolumeMutedIcon = styled(VolumeOffIcon, { shouldForwardProp: (prop) => prop !== "isVolumeMuted" })<{
  isVolumeMuted: boolean;
}>(({ isVolumeMuted }) => ({
  display: isVolumeMuted ? "block" : "none", // Prevents icon section from "jumping" on the first mute by prerendering the icon
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
}));

export const StyledPopOutIcon = styled(PopoutIcon)(({ theme }) => ({
  ...sharedIconStyles,
  ...sharedPseudoIconElementStyles,
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

export const VolumeControlsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  maxHeight: 24,
}));

const volumeBarPseudoTrackElementSharedStyles = {
  height: 4,
  borderRadius: 2,
};

const volumeBarPseudoThumbElementSharedStyles = {
  height: 12,
  width: 12,
  borderRadius: "50%",
  border: "none",
  background: colors.neutral0,
};

export const VolumeBar = styled("input")<{ volumePercentage: string }>(({ volumePercentage }) => ({
  WebkitAppearance: "none",
  background: "none",
  width: 72,
  cursor: "pointer",

  "&::-webkit-slider-runnable-track": {
    ...volumeBarPseudoTrackElementSharedStyles,
    WebkitAppearance: "none",
    background: `linear-gradient(to right, ${colors.secondary} 0% ${volumePercentage}, ${colors.neutral10} ${volumePercentage} 100%)`,
  },

  "&::-webkit-slider-thumb": {
    ...volumeBarPseudoThumbElementSharedStyles,
    WebkitAppearance: "none",
    marginTop: -4,
  },
  "&::-moz-range-track": {
    ...volumeBarPseudoTrackElementSharedStyles,
    background: `linear-gradient(to right, ${colors.secondary} 0% ${volumePercentage}, ${colors.neutral10} ${volumePercentage} 100%)`,
  },
  "&::-moz-range-thumb": {
    ...volumeBarPseudoThumbElementSharedStyles,
  },
}));
