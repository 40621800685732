import { QueryClient } from "@tanstack/react-query";

const isClient = typeof window !== "undefined";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      gcTime: isClient ? 5 * 60 * 1000 : 0, // (2 minutes) no caching during SSR to prevent hydration errors
    },
  },
});

export const refetchPageQuery = () => {
  queryClient.refetchQueries({
    queryKey: ["page"],
    exact: false,
  });
};
