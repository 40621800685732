import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  format,
  formatDistanceToNowStrict,
  isThisYear,
  isToday,
  isYesterday,
} from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import nlLocal from "date-fns/locale/nl";
import { Dates, TIMEZONE, TODAY, YESTERDAY } from "@constants/date";
import capitalize from "@utils/common/capitalize";

export const getUpdateStatus = (firstPublishedAt: string, updatedAt: string | null) => {
  if (!updatedAt) return "";

  const today = new Date();
  const firstPublishedAtDate = new Date(firstPublishedAt);
  const updatedAtDate = new Date(updatedAt);

  const difference = formatDistanceToNowStrict(updatedAtDate, {
    locale: nlLocal,
    addSuffix: true,
  });

  const minutesDifference = differenceInMinutes(updatedAtDate, firstPublishedAtDate);

  if (minutesDifference < 15) {
    return "";
  }

  if (minutesDifference > 15 && differenceInHours(today, firstPublishedAtDate) < 24) {
    return ` - Update: ${difference}`;
  }

  return ` - Update: ${getDateLabel(updatedAt)}`;
};

export const getDateLabel = (
  firstPublishedAt: string,
  { showTime, showDay }: { showTime?: boolean; showDay?: boolean } = { showTime: true }
) => {
  try {
    const firstPublishedAtDate = utcToZonedTime(new Date(firstPublishedAt), TIMEZONE);

    if (isToday(firstPublishedAtDate)) return format(firstPublishedAtDate, `'${TODAY}'${showTime ? ", HH:mm" : ""}`);
    if (isYesterday(firstPublishedAtDate))
      return format(firstPublishedAtDate, `'${YESTERDAY}'${showTime ? ", HH:mm" : ""}`);

    if (showDay && differenceInCalendarDays(new Date(), firstPublishedAtDate) < 7) {
      return capitalize(
        format(firstPublishedAtDate, `EEEE d '${Dates[firstPublishedAtDate.getMonth()]}', HH:mm`, { locale: nlLocal })
      );
    }

    if (showDay && isThisYear(firstPublishedAtDate)) {
      return format(firstPublishedAtDate, `d '${Dates[firstPublishedAtDate.getMonth()]}', HH:mm`);
    }

    return format(
      firstPublishedAtDate,
      `d '${Dates[firstPublishedAtDate.getMonth()]}' yyyy${showTime ? ", HH:mm" : ""}`,
      {
        locale: nlLocal,
      }
    );
  } catch {
    return "";
  }
};
