import { NOW_LIVE } from "@constants/consts";
import { Schedule } from "@utils/audio/dataModels";
import * as Styled from "../RadioSchedule.styled";

const RadioScheduleItem: React.FC<Pick<Schedule, "title" | "showTime" | "isLive" | "thumbnailUrl">> = ({
  title,
  showTime,
  isLive,
  thumbnailUrl,
}) => {
  return (
    // temporary until we have the show page
    <Styled.ItemContainer href={"/"}>
      <Styled.TextContainer>
        <Styled.TimeAndTitleContainer>
          <Styled.ShowTime>{showTime}</Styled.ShowTime>
          <Styled.ShowTitle>{title}</Styled.ShowTitle>
        </Styled.TimeAndTitleContainer>
        {isLive && <Styled.LiveLabel>{NOW_LIVE}</Styled.LiveLabel>}
      </Styled.TextContainer>
      {thumbnailUrl && (
        <Styled.AspectRatio aspectRatio="4:3">
          <Styled.ImageBackground />
          <Styled.ShowImage fill src={thumbnailUrl} alt={title} sizes="(max-width: 900px) 300px, 400px" />
        </Styled.AspectRatio>
      )}
    </Styled.ItemContainer>
  );
};

export default RadioScheduleItem;
