import { useEffect, useState } from "react";
import audioPlayerManager from "@audio/AudioPlayerManager/AudioPlayerManager";
import { BUTTON_SIZE, VARIANT } from "@constants/button";
import { NOW_LIVE_LABEL } from "@constants/consts";
import { Station, Track } from "@utils/audio/dataModels";
import * as Styled from "./NowPlaying.styled";

const NowPlaying: React.FC<{ station: Station; playerType: AudioPlayerEventParams["playerType"] }> = ({
  station,
  playerType,
}) => {
  const [track, setTrack] = useState<Track | null>(null);

  useEffect(() => {
    const onNowPlaying = (event: Track | null) => {
      setTrack(event);
    };

    audioPlayerManager.on("nowPlaying", onNowPlaying);

    return () => {
      audioPlayerManager.off("nowPlaying", onNowPlaying);
    };
  }, []);

  return (
    <Styled.NowPlayingContainer>
      <Styled.NowPlayingImageContainer>
        <Styled.NowPlayingImage
          src={track?.imageUrl || station.thumbnailUrl}
          alt={track?.artistName || station.title}
          fill
          sizes="(max-width: 900px) 50px, 100px"
        />
      </Styled.NowPlayingImageContainer>
      <Styled.NowPlayingTextContainer>
        <Styled.NowPlayingTitle>{track?.title || station.title}</Styled.NowPlayingTitle>
        <Styled.NowPlayingSecondaryRowContainer>
          {playerType !== "web" && (
            <Styled.NowPlayingLiveLabel variant={VARIANT.SECONDARY} size={BUTTON_SIZE.EXTRA_SMALL} isNonInteractive>
              {NOW_LIVE_LABEL}
            </Styled.NowPlayingLiveLabel>
          )}
          <Styled.NowPlayingSecondaryTitle>
            {track?.artistName || station.secondaryTitle}
          </Styled.NowPlayingSecondaryTitle>
        </Styled.NowPlayingSecondaryRowContainer>
      </Styled.NowPlayingTextContainer>
    </Styled.NowPlayingContainer>
  );
};

export default NowPlaying;
