import { styled } from "@mui/material";
import { gradients } from "@constants/cssVariables";
import { zIndex } from "@constants/zIndex";

export const AudioPlayerContainer = styled("div")(({ theme }) => ({
  position: "fixed",
  zIndex: zIndex.floating,
  bottom: 0,
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  width: "calc(100% - 16px)",
  height: 64,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: gradients.gradient04,
  borderRadius: 12,
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(0, 4, 0, 2),
  },
  [theme.breakpoints.up("md")]: {
    width: "100%",
    margin: 0,
    height: 96,
    padding: theme.spacing(0, 8),
    borderRadius: 0,
  },
  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 20),
  },
  [theme.breakpoints.up("xl")]: {
    padding: theme.spacing(0, 24),
  },
}));
