import { styled } from "@mui/material";
import {
  BUTTON_SIZE,
  BUTTON_TYPE,
  VARIANT,
  buttonTextSizes,
  buttonWrapperSizes,
  variantColors,
} from "@constants/button";
import { buttonBorderRadiusDefaultValue } from "@constants/consts";
import { borders, fonts, transformations } from "@constants/cssVariables";
import { ButtonText } from "../Button/Button";

const ButtonContainer = styled("button", {
  shouldForwardProp: (prop: string) => !["fullWidth", "buttonType", "as", "variant", "isNonInteractive"].includes(prop),
})<{
  variant: VARIANT;
  buttonType: BUTTON_TYPE;
  size: BUTTON_SIZE;
  fullWidth?: boolean;
  isNonInteractive?: boolean;
}>(({ variant, buttonType, size, fullWidth, isNonInteractive, theme }) => ({
  display: buttonType === BUTTON_TYPE.DEFAULT ? "inline-flex" : "flex",
  cursor: "pointer",
  height: buttonWrapperSizes[size].height,
  width: buttonType === BUTTON_TYPE.ICON ? buttonWrapperSizes[size].width : "auto",
  maxWidth: "100%",
  padding: buttonWrapperSizes[size].padding,
  justifyContent: "center",
  alignItems: "center",
  flexShrink: 0,
  backgroundColor: variantColors[variant].backgroundColor,
  borderRadius: buttonType === BUTTON_TYPE.ICON ? buttonBorderRadiusDefaultValue : borders.buttonBorderRadius,
  border: variantColors[variant].borderColor ? `2px solid ${variantColors[variant].borderColor}` : "none",
  ...(buttonType === BUTTON_TYPE.DEFAULT &&
    fullWidth && {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "300px",
      },
    }),

  "@media (hover: hover)": {
    "&:hover": {
      border:
        variantColors[variant].borderColorHover && !isNonInteractive
          ? `2px solid ${variantColors[variant].borderColorHover}`
          : "none",
      backgroundColor: isNonInteractive ? variantColors[variant].backgroundColor : variantColors[variant].hoverColor,
      "svg, p": {
        color: isNonInteractive ? variantColors[variant].contentColor : variantColors[variant].hoverContentColor,
      },
    },
  },

  "&:active": {
    border:
      variantColors[variant].borderColorActive && !isNonInteractive
        ? `2px solid ${variantColors[variant].borderColorActive}`
        : "none",
    backgroundColor: isNonInteractive ? variantColors[variant].backgroundColor : variantColors[variant].activeColor,
    "svg, p": {
      color: isNonInteractive ? variantColors[variant].contentColor : variantColors[variant].activeContentColor,
    },
  },
  "&:disabled": {
    cursor: "not-allowed",
    border: variantColors[variant].borderColorDisabled
      ? `2px solid ${variantColors[variant].borderColorDisabled}`
      : "none",
    backgroundColor: variantColors[variant].disabledBackgroundColor,
    "svg, p": {
      color: variantColors[variant].disabledContentColor,
    },
  },

  [`${ButtonText}`]: {
    padding: buttonTextSizes[size].padding,
    color: variantColors[variant].contentColor,
    font: buttonTextSizes[size].font,
    textTransform: transformations.buttonTextTransform,
    fontStyle: fonts.buttonFontStyle,
    "&:hover": {
      color: variantColors[variant].hoverContentColor,
    },
    "&:active": {
      color: variantColors[variant].activeContentColor,
    },
  },
}));

export default ButtonContainer;
