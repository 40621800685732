import React, { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import Tealium from "@4tn/webx-analytics";
import { PrimaryButton } from "@common/Buttons";
import { SpinnerIcon } from "@common/Icons";
import { ENTER_YOUR_EMAIL_ADDRESS, SUBSCRIBE, YOU_ARE_ALMOST_THERE, newsletterErrorMessages } from "@constants/consts";
import { TEALIUM_EVENT_CATEGORY, TEALIUM_EVENT_LABEL, TEALIUM_EVENT_NAME } from "@constants/tealium.constants";
import { FeatureSlugs, useFeature } from "@utils/common/featureTooling";
import { useHostConfig } from "@utils/common/getHostConfig";
import validateEmail from "@utils/common/validateEmail";
import * as Styled from "./Subscription.styled";

const emailInputAriaLabel = "email";

const trackClick = (isEmbedded?: boolean) => {
  Tealium.link({
    event_category: isEmbedded ? TEALIUM_EVENT_CATEGORY.CONTENT_LISTING : TEALIUM_EVENT_CATEGORY.FOOTER,
    event_name: isEmbedded ? TEALIUM_EVENT_NAME.CONTENT_LISTING_CLICK : TEALIUM_EVENT_NAME.FOOTER_CLICK,
    event_label: TEALIUM_EVENT_LABEL.NEWSLETTER_SUBSCRIBE,
  });
};

const Subscription: React.FC<{ isEmbedded?: boolean }> = ({ isEmbedded }) => {
  const { enabled: legalTextEnabled, variables: featureVariables } = useFeature<{ legalText: string }>(
    FeatureSlugs.SUBSCRIPTION_TEXT
  );
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

  const { subscription, outletName } = useHostConfig();
  const pathname = usePathname();

  useEffect(() => {
    setSubscriptionSuccess(false);
    setEmail("");
  }, [pathname]);

  const subscribe = async (event: React.FormEvent) => {
    event.preventDefault();

    const emailValid = validateEmail(email);

    if (!emailValid) {
      setErrorMessage(newsletterErrorMessages.EMAIL_INVALID);
      return;
    }

    setIsLoading(true);

    try {
      const result = await fetch(subscription.subscriptionApiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          subscription_text: subscription.subscriptionText,
          extras: ["origin:website_subscribe"],
        }),
      });

      if (result.ok) {
        setSubscriptionSuccess(true);
      } else {
        throw new Error("Error while subscribing to the newsletter");
      }
    } catch (err) {
      console.log(err);
      setSubscriptionSuccess(false);
      setErrorMessage(newsletterErrorMessages.FETCH_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errorMessage && validateEmail(event.target.value)) {
      setErrorMessage(null);
    }
    setEmail(event.target.value);
  };

  if (!subscription || !subscription.subscriptionEnabled) return null;

  if (subscriptionSuccess) {
    return (
      <Styled.Container>
        <Styled.TitleSubtitleContainer>
          <Styled.Title>{YOU_ARE_ALMOST_THERE}</Styled.Title>
          <Styled.Subtitle>
            Je ontvang binnen enkele minuten een e-mail op
            <Styled.ConfirmedEmail> {email},</Styled.ConfirmedEmail> hierin vind je een link om je inschrijving op de{" "}
            {outletName} nieuwsbrief te bevestigen.
          </Styled.Subtitle>
        </Styled.TitleSubtitleContainer>
      </Styled.Container>
    );
  }

  return (
    <Styled.Container>
      <Styled.TitleSubtitleContainer>
        <Styled.Title>{subscription.newsletterTitle}</Styled.Title>
        <Styled.Subtitle>{subscription.newsletterSubtitle}</Styled.Subtitle>
      </Styled.TitleSubtitleContainer>
      <Styled.InputAndButtonContainer onSubmit={subscribe} noValidate>
        <Styled.Input
          type="email"
          required
          hasError={!!errorMessage}
          name="email"
          aria-label={emailInputAriaLabel}
          value={email}
          onChange={handleEmailChange}
          placeholder={ENTER_YOUR_EMAIL_ADDRESS}
        />
        {errorMessage && <Styled.ErrorText>{errorMessage}</Styled.ErrorText>}
        <PrimaryButton type="submit" onClick={() => trackClick(isEmbedded)} disabled={isLoading}>
          {isLoading ? <SpinnerIcon /> : SUBSCRIBE}
        </PrimaryButton>
      </Styled.InputAndButtonContainer>
      <Styled.LegalText>
        {legalTextEnabled && featureVariables ? (
          <p dangerouslySetInnerHTML={{ __html: featureVariables.legalText }} />
        ) : (
          <>
            <p>{subscription.subscriptionText}</p>
            <p dangerouslySetInnerHTML={{ __html: subscription.fallbackLegalText }} />
          </>
        )}
      </Styled.LegalText>
    </Styled.Container>
  );
};

export default Subscription;
