import { styled } from "@mui/material";
import * as StyledSubscription from "@navigation/Subscription/Subscription.styled";
import { ButtonContainer } from "@common/Buttons";
import { maxPageContentWidth, transitions } from "@constants/consts";
import { colors, fonts } from "@constants/cssVariables";

export const FooterWrapper = styled("div")(({ theme }) => ({
  backgroundColor: colors.neutral90,
  color: colors.neutral0,
  width: "100%",
  marginTop: theme.spacing(20),
  padding: theme.spacing(10, 0, 12),
  transition: transitions.hamburgerMenu,

  div: {
    paddingTop: 0,
  },

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(12, 0),
  },

  [`${StyledSubscription.Container}`]: {
    padding: theme.spacing(0, 0, 10),
    backgroundColor: "transparent",
    borderBottom: `1px solid ${colors.neutral70}`,
    marginBottom: theme.spacing(10),
    borderRadius: 0,

    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 0, 10),
    },

    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 0, 10),
    },

    [`${StyledSubscription.TitleSubtitleContainer}`]: {
      [theme.breakpoints.up("md")]: {
        width: 650,
      },
      [theme.breakpoints.up("lg")]: {
        width: 850,
      },
    },

    [`${StyledSubscription.Title}`]: {
      color: colors.neutral0,
    },

    [`${StyledSubscription.Subtitle}`]: {
      color: colors.neutral30,
    },

    [`${StyledSubscription.InputAndButtonContainer}`]: {
      [theme.breakpoints.up("md")]: {
        width: 488,
      },

      [theme.breakpoints.up("lg")]: {
        width: 616,
      },
    },
  },
}));

export const ItemsWrapper = styled("div")(({ theme }) => ({
  maxWidth: maxPageContentWidth,
  margin: "0 auto",
  padding: theme.spacing(0, 4),

  [theme.breakpoints.up("md")]: {
    padding: theme.spacing(0, 20),
  },

  [theme.breakpoints.up("lg")]: {
    padding: theme.spacing(0, 24),
  },
}));

export const SubmenuContainer = styled("div")(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(12, 1fr)",
  gap: theme.spacing(4),
  rowGap: theme.spacing(8),
}));

export const SubmenuItems = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gridColumn: "12 span",
  gap: theme.spacing(8),

  [theme.breakpoints.up("md")]: {
    gridColumn: "6 span",
  },

  [theme.breakpoints.up("lg")]: {
    gap: theme.spacing(4),
    gridColumn: "9 span",
    flexDirection: "row",
  },
}));

export const MarketingItems = styled("div")(({ theme }) => ({
  gridColumn: "12 span",

  [`${Submenu}`]: {
    [`${SubmenuItemTitle}:hover`]: {
      color: colors.neutral30,
    },
  },

  [theme.breakpoints.up("md")]: {
    gridColumn: "6 span",
  },

  [theme.breakpoints.up("lg")]: {
    gridColumn: "3 span",
  },
}));

export const Submenu = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),

  [`> ${ButtonContainer}`]: {
    width: 200,
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      width: 177,
    },
  },

  [theme.breakpoints.up("md")]: {
    gap: theme.spacing(2),
    flex: 1,
  },

  [theme.breakpoints.up("lg")]: {
    flex: 1,
  },
}));

export const Socials = styled(Submenu)(({ theme }) => ({
  gap: theme.spacing(5),
}));

export const SubmenuTitle = styled("div")(({ theme }) => ({
  font: fonts.header5,
  color: colors.neutral0,
  marginBottom: theme.spacing(0.5),
}));

export const SubmenuItemTitle = styled("div")(() => ({
  font: fonts.footer,
  color: colors.neutral30,
  ":hover": {
    color: colors.neutral0,
  },
}));

export const RightsText = styled("div")(({ theme }) => ({
  font: fonts.bodySmall,
  marginTop: theme.spacing(10),
  [theme.breakpoints.up("md")]: {
    width: 732,
  },
  [theme.breakpoints.up("lg")]: {
    width: 1040,
  },
  [theme.breakpoints.up("xl")]: {
    width: 1248,
  },
}));
