import { StationSubscription, fetchCurrentTrack } from "@utils/audio/audioApi";
import { Track } from "@utils/audio/dataModels";
import createScriptElement from "@utils/common/createScriptElement";
import { getConsentData } from "@utils/common/getConsentData";
import getHostConfig from "@utils/common/getHostConfig";
import WebAudioPlayer from "./WebAudioPlayer";

const playerEvents: Array<TDSdkPlayerEvent> = [
  "stream-start",
  "stream-stop",
  "stream-status",
  "track-cue-point",
  "ad-break-cue-point",
  "ad-break-cue-point-complete",
];

const statusToKey: Record<TDSdkState, AudioPlayerEventKey> = {
  LIVE_PAUSE: "pause",
  LIVE_PLAYING: "play",
  LIVE_STOP: "stop",
  LIVE_FAILED: "error",
  LIVE_BUFFERING: "loading",
  LIVE_CONNECTING: "loading",
  LIVE_RECONNECTING: "loading",
  GETTING_STATION_INFORMATION: "loading",
  STREAM_GEO_BLOCKED: "error",
  STREAM_GEO_BLOCKED_ALTERNATE: "error",
  STREAM_GEO_BLOCKED_NO_ALTERNATE: "error",
  STATION_NOT_FOUND: "error",
  PLAY_NOT_ALLOWED: "error",
};

class TritonAudioPlayer extends WebAudioPlayer {
  tritonPlayer: TDSdkPlayer;
  currentSource: string | null = null;
  streamStatus: string;
  talpaConsent: string;
  distTag: string;
  stationSubscription: StationSubscription | null = null;

  onCallback =
    (eventName: TDSdkPlayerEvent) =>
    (event: TDSdkEventParams[TDSdkPlayerEvent]): void => {
      if (eventName === "stream-start") {
        this.isPlaying = true;
      }
      if (eventName === "stream-stop") {
        this.isPlaying = false;
        //this.dispatch("nowPlaying", null);
      }
      if (eventName === "ad-break-cue-point") {
        this.dispatch("adStarted", { details: "Commercial break..." });
      }
      if (eventName === "ad-break-cue-point-complete") {
        this.dispatch("adComplete", { details: this.streamStatus });
      }
      if (eventName === "stream-status") {
        const { data } = event as TDSdkEventParams["stream-status"];
        this.streamStatus = data.status;
        this.dispatch(statusToKey[data.code], { details: data.status });
      }
      if (eventName === "track-cue-point") {
        const { data } = event as TDSdkEventParams["track-cue-point"];
        //this.dispatch("nowPlaying", { title: data.cuePoint.cueTitle, artistName: data.cuePoint.artistName });
        console.log(data);
      }
      if (eventName === "ad-break-cue-point") {
        const { data } = event as TDSdkEventParams["ad-break-cue-point"];
        //this.dispatch("nowPlaying", { title: data.cuePoint.cueTitle });
        console.log(data);
      }
    };

  addEventListeners(): void {
    playerEvents.forEach((eventName) => {
      this.tritonPlayer.addEventListener(eventName, this.onCallback(eventName));
    });
  }
  removeEventListeners(): void {
    playerEvents.forEach((eventName) => {
      this.tritonPlayer.removeEventListener(eventName, this.onCallback(eventName));
    });
  }

  private async setup(isHls = false): Promise<void> {
    if (typeof TDSdk === "undefined") {
      await createScriptElement("https://sdk.listenlive.co/web/2.9/td-sdk.min.js?_=1638265229969");
    }

    return new Promise<void>(async (resolve, reject) => {
      if (this.tritonPlayer) {
        // Check whether the stream is an HLS one
        if (isHls && this.tritonPlayer.MediaPlayer.hls) {
          return resolve();
        }
        console.log("New Triton SDK should be added");
        this.removeEventListeners();
        this.tritonPlayer.stop();
        this.tritonPlayer.destroy();
        await this.waitForStop();
      }

      const { audioDistTag } = getHostConfig();
      this.distTag = audioDistTag;

      const { tcString, talpaConsent } = await getConsentData();
      this.talpaConsent = talpaConsent;

      const tdPlayerConfig: TDSdkConfig = {
        coreModules: [
          {
            id: "MediaPlayer",
            playerId: "td_container",
            techPriority: ["Html5"],
            streamWhileMuted: true,
            debug: true,
            hls: isHls,
            idSync: {
              mount: this.currentSource,
              gdpr: 1, // States whether or not GDPR applies. Always 1
              gdpr_consent: tcString,
            },
            geoTargeting: {
              desktop: {
                isActive: false,
              },
              iOS: {
                isActive: false,
              },
              android: {
                isActive: false,
              },
            },
            plugins: [
              {
                id: "vastAd",
              },
              {
                id: "mediaAd",
              },
              {
                id: "onDemand",
              },
            ],
          },
          { id: "NowPlayingApi" },
        ],
        playerReady: () => {
          console.log("playerReady");
          // Resolve the promise when the player is ready
          this.audioElement = this.tritonPlayer.MediaElement;
          this.addEventListeners();
          resolve();
        },
        configurationError: (error) => {
          // Reject the promise on configuration error
          reject(new Error(`Configuration Error: ${error}`));
        },
        moduleError: (error) => {
          // Reject the promise on module error
          reject(new Error(`Module Error: ${error}`));
        },
        adBlockerDetected: (output) => {
          // Handle ad blocker detection
          console.warn("Ad Blocker detected", output);
        },
      };

      this.tritonPlayer = new TDSdk(tdPlayerConfig);
    });
  }

  private async waitForStop(): Promise<void> {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (this.isPlaying === false) {
          clearInterval(interval);
          resolve();
        }
      }, 1000);
    });
  }

  async play(source: string): Promise<void> {
    if (!source) return;
    this.currentSource = source;
    const isHls = source.includes(".m3u8");
    await this.setup(isHls);
    this.tritonPlayer.setVolume(0.5);

    if (this.stationSubscription) {
      this.cleanUpSubscription();
    }
    // todo: Use the provided station slug in play arguments instead of mainStationSlug
    const { mainStationSlug } = getHostConfig();
    fetchCurrentTrack(mainStationSlug).then((currentTrack) => {
      if (currentTrack) this.dispatch("nowPlaying", currentTrack);
    });

    this.stationSubscription = new StationSubscription(mainStationSlug, this.onTrackEvent);

    if (isHls) {
      this.tritonPlayer.MediaPlayer.tech.playStream({
        url: source,
        forceTimeShift: true,
        aSyncCuePoint: {
          active: false,
        },
        isHLS: true,
      });
      return;
    }

    this.tritonPlayer.play({
      mount: source,
      trackingParameters: { dist: this.distTag, ttag: `talpa_consent:${this.talpaConsent}` },
    });
  }

  async stop(): Promise<void> {
    this.currentSource = null;
    this.tritonPlayer.stop();
    await this.waitForStop();
  }

  cleanUpSubscription(): void {
    if (this.stationSubscription) {
      this.stationSubscription.cleanUp();
      this.stationSubscription = null;
    }
  }

  async resume(): Promise<void> {
    // Triton streams can't be resumed
    if (!this.currentSource) return;
    const source = this.currentSource;
    if (this.isPlaying) {
      await this.stop();
    }
    this.play(source);
  }

  pause(): void {
    this.tritonPlayer.stop();
  }

  mute(): void {
    this.tritonPlayer.mute();
    // added since volumeChange event does not get triggered on mute
    this.dispatch("mute", { details: "Muted" });
  }

  unmute(): void {
    this.tritonPlayer.unMute();
    // added since volumeChange event does not get triggered on unmute
    this.dispatch("playerVolume", { volume: this.tritonPlayer.getVolume() });
  }

  volumeChange(volume: number): void {
    this.tritonPlayer.setVolume(volume);
    this.dispatch("playerVolume", { volume });
  }

  onTrackEvent = (track: Track): void => {
    this.dispatch("nowPlaying", track);
  };
}
export default TritonAudioPlayer;
