import { Theme, useMediaQuery } from "@mui/material";
import { ScreenSizes } from "@constants/consts";

const useScreenSize = () => {
  const isExtraSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isSmallScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only("sm"));
  const isMediumScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only("md"));
  const isLargeScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.only("lg"));
  const isExtraLargeScreen = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));

  if (isExtraSmallScreen) return ScreenSizes.xs;
  if (isSmallScreen) return ScreenSizes.sm;
  if (isMediumScreen) return ScreenSizes.md;
  if (isLargeScreen) return ScreenSizes.lg;
  if (isExtraLargeScreen) return ScreenSizes.xl;
};

export default useScreenSize;
