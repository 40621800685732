import React from "react";
import Link from "next/link";
import { useQuery } from "@tanstack/react-query";
import { fetchRadioSchedule } from "@utils/audio/audioApi";
import { useNoticeError } from "@utils/common/newRelic";
import * as Styled from "./RadioSchedule.styled";
import RadioScheduleItem from "./RadioScheduleItem/RadioScheduleItem";
import RadioScheduleSkeleton from "./RadioScheduleSkeleton";

const RadioSchedule: React.FC<RadioSchedule> = ({ stationSlug, title, showAllText, showAllUrl }) => {
  const radioScheduleQueryKey = ["radio-schedule", stationSlug];

  const {
    isLoading,
    data: items,
    error,
  } = useQuery({
    queryKey: radioScheduleQueryKey,
    queryFn: () => fetchRadioSchedule(stationSlug),
    enabled: !!stationSlug,
  });

  useNoticeError(error, { queryKey: radioScheduleQueryKey.join(",") });

  if (!isLoading && !items) return null;

  if (isLoading || !items?.length) return <RadioScheduleSkeleton hasShowAllText={!!showAllText} />;

  return (
    <Styled.RadioScheduleContainer>
      <Styled.RadioScheduleHeader>
        <Styled.RadioScheduleTitle>{title}</Styled.RadioScheduleTitle>
        {showAllUrl && (
          <Styled.RadioScheduleShowAll>
            <Link href={showAllUrl}>{showAllText}</Link>
          </Styled.RadioScheduleShowAll>
        )}
      </Styled.RadioScheduleHeader>
      <Styled.ScheduleSwimlane scrollToItemIndex={0} hasHoverEffect={true}>
        {items.map((schedule, index) => (
          <React.Fragment key={schedule.id}>
            {(!items[index - 1] || items[index - 1].day !== schedule.day) && (
              <Styled.DayDivider>
                <span>{schedule.day}</span>
              </Styled.DayDivider>
            )}
            <RadioScheduleItem {...schedule} />
          </React.Fragment>
        ))}
      </Styled.ScheduleSwimlane>
    </Styled.RadioScheduleContainer>
  );
};

export default RadioSchedule;
