import { buttonBorderRadiusDefaultValue, buttonFontStyleDefaultValue } from "./consts";

export const fonts = {
  buttonSmall: "var(--font-button-small)",
  buttonMedium: "var(--font-button-medium)",
  buttonLarge: "var(--font-button-large)",
  header1: "var(--font-header-1)",
  header2: "var(--font-header-2)",
  header3: "var(--font-header-3)",
  header4: "var(--font-header-4)",
  header5: "var(--font-header-5)",
  header6: "var(--font-header-6)",
  header7: "var(--font-header-7)",
  footer: "var(--font-footer)",
  bodyExtraSmall: "var(--font-body-extra-small)",
  bodyExtraSmallStrong: "var(--font-body-extra-small-strong)",
  bodySmall: "var(--font-body-small)",
  bodySmallStrong: "var(--font-body-small-strong)",
  bodyMedium: "var(--font-body-medium)",
  bodyMediumStrong: "var(--font-body-medium-strong)",
  bodyMediumItalic: "var(--font-body-medium-italic)",
  bodyLarge: "var(--font-body-large)",
  bodyLargeStrong: "var(--font-body-large-strong)",
  tab: "var(--font-tab)",
  tabStrong: "var(--font-tab-strong)",
  category: "var(--font-category)",
  buttonFontStyle: `var(--button-font-style, ${buttonFontStyleDefaultValue})`,
};

export const colors = {
  primary50: "var(--primary-50-color)",
  primary: "var(--primary-100-color)",
  primary120: "var(--primary-120-color)",
  primary140: "var(--primary-140-color)",
  secondary10: "var(--secondary-10-color)",
  secondary50: "var(--secondary-50-color)",
  secondary: "var(--secondary-100-color)",
  secondary100: "var(--secondary-100-color)",
  secondary120: "var(--secondary-120-color)",
  secondary140: "var(--secondary-140-color)",
  neutral0: "var(--neutral-0-color)",
  neutral10: "var(--neutral-10-color)",
  neutral20: "var(--neutral-20-color)",
  neutral30: "var(--neutral-30-color)",
  neutral40: "var(--neutral-40-color)",
  neutral50: "var(--neutral-50-color)",
  neutral60: "var(--neutral-60-color)",
  neutral70: "var(--neutral-70-color)",
  neutral80: "var(--neutral-80-color)",
  neutral90: "var(--neutral-90-color)",
  neutral100: "var(--neutral-100-color)",
  danger100: "var(--danger-100-color)",
  tabBar: "var(--tab-bar-color)",
  header: "var(--header-color)",
  button: {
    primaryBackgroundColor: "var(--button-primary-background-color, var(--primary-100-color))",
    secondaryBackgroundColor: "var(--button-secondary-background-color, var(--secondary-100-color))",
    primaryTextColor: "var(--button-primary-text-color, var(--neutral-0-color))",
    secondaryTextColor: "var(--button-secondary-text-color, var(--neutral-0-color))",
    textColor: "var(--button-text-color, var(--primary-color))",
    hoverPrimaryBackgroundColor: "var(--button-hover-primary-background-color, var(--primary-120-color))",
    hoverSecondaryBackgroundColor: "var(--button-hover-secondary-background-color, var(--secondary-120-color))",
    pressedPrimaryBackgroundColor: "var(--button-pressed-primary-background-color, var(--primary-50-color))",
    pressedSecondaryBackgroundColor: "var(--button-pressed-secondary-background-color, var(--secondary-50-color))",
    hoverPrimaryTextColor: "var(--button-hover-primary-text-color, --button-text-color)",
    pressedPrimaryTextColor: "var(--button-pressed-primary-text-color, --button-text-color)",
  },
};

export const gradients = {
  fadeBlackBottomTop: "var(--fade-black-bottom-top)",
  fadeBlack90BottomTop: "var(--fade-black-90-bottom-top)",
  fadePrimaryRight: "var(--fade-primary-right)",
  fadePrimaryBottomTop: "var(--fade-primary-bottom-top)",
  fadePageHeaderBottomTop: "var(--fade-page-header-bottom-top, var(--fade-primary-bottom-top))",
  fadePrimaryLeftRight: "var(--fade-primary-left-right)",
  fadePageHeaderLeftRight: "var(--fade-page-header-left-right, var(--fade-primary-left-right))",
  gradient01: "var(--gradient-01)",
  gradient02: "var(--gradient-02)",
  gradient03: "var(--gradient-03)",
  gradient04: "var(--gradient-04)",
  fadeWhiteLeft: "var(--fade-white-left)",
  fadeWhiteRight: "var(--fade-white-right)",
  fadePrimary90Left: "var(--fade-primary-90-left)",
  fadePrimary90Right: "var(--fade-primary-90-right)",
};

export const opacities = {
  opacity01: "var(--opacity-01)",
  opacity02: "var(--opacity-02)",
};

export const borders = {
  buttonBorderRadius: `var(--button-border-radius, ${buttonBorderRadiusDefaultValue}px)`,
};

export const transformations = {
  buttonTextTransform: `var(--button-text-transform, none)`,
};

export const grid = {
  articleGridColumnsMD: `var(--article-grid-columns-md)`,
  articleGridColumnsLG: `var(--article-grid-columns-lg)`,
  articleGridColumnsXL: `var(--article-grid-columns-xl)`,
};
