import { format, isAfter, isBefore, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import nl from "date-fns/locale/nl";
import { radioProfile } from "@constants/consts";
import { TIMEZONE } from "@constants/date";
import { UniversalApiRadioSchedule, UniversalApiStation, UniversalApiTrack } from "@typings/audio";
import capitalize from "@utils/common/capitalize";

export class Schedule {
  id: string;
  startTime: string;
  endTime: string;
  showTime: string;
  title: string;
  slug: string;
  thumbnailUrl: string = "";
  isLive: boolean;
  day: string;
  constructor(data: UniversalApiRadioSchedule) {
    const today = utcToZonedTime(new Date(), TIMEZONE);
    const showStartDate = utcToZonedTime(new Date(data.broadcastStartDate), TIMEZONE);
    const showEndDate = utcToZonedTime(new Date(data.broadcastEndDate), TIMEZONE);

    this.id = data.id;
    this.slug = data.show.slug;
    this.title = data.show.title;
    this.startTime = format(showStartDate, "HH:mm", { locale: nl });
    this.endTime = format(showEndDate, "HH:mm", { locale: nl });
    this.showTime = `${this.startTime} - ${this.endTime}`;
    this.day = capitalize(format(parseISO(data.broadcastStartDate), "EEEEEE", { locale: nl }));
    this.isLive = isAfter(today, showStartDate) && isBefore(today, showEndDate);
    if (data.show.images.length) {
      const thumbnail = data.show.images.find((image) => image.variant?.slug.includes(radioProfile));
      this.thumbnailUrl = thumbnail ? thumbnail.uri : data.show.images[0].uri;
    }
  }
}
export class Station {
  slug: string;
  type: string;
  mountSource: string;
  title: string;
  secondaryTitle: string;
  thumbnailUrl: string;
  constructor(data: UniversalApiStation) {
    this.slug = data.slug;
    this.type = data.type;
    this.mountSource = data.media.find((mediaItem) => mediaItem.source === "mount_point")?.uri || "";
    this.title = data.title;
    this.secondaryTitle = data.shortTitle;
    this.thumbnailUrl = data.images.find((image) => image.imageType === "image")?.uri || "";
  }
}

export class Track implements NowPlayingDetails {
  id: string;
  isrc: string;
  title: string;
  artistName: string;
  imageUrl: string = "";

  constructor(data: UniversalApiTrack) {
    this.id = data.id;
    this.isrc = data.isrc;
    this.title = data.title;
    this.artistName = data.artistName;

    if (data.images?.length) {
      this.imageUrl = data.images[0].uri;
    }
  }
}
