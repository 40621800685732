import getConfig from "next/config";
import { addDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { print, stripIgnoredCharacters } from "graphql";
import gql from "graphql-tag";
import type { DocumentNode } from "graphql/language/ast";
import { radioProfile } from "@constants/consts";
import { TIMEZONE } from "@constants/date";
import { UniversalApiRadioSchedule, UniversalApiStation } from "@typings/audio";
import { Schedule, Station, Track } from "./dataModels";

const {
  publicRuntimeConfig: { audioApiEndpoint, audioXApiKey, appsyncApiKey, appsyncHost },
} = getConfig();

const RadioScheduleQuery = gql`
  query RadioScheduleQuery($stationSlug: String, $startDate: AWSDateTime, $endDate: AWSDateTime) {
    station(slug: $stationSlug) {
      schedules(broadcastStartDate: $startDate, broadcastEndDate: $endDate) {
        broadcastStartDate
        broadcastEndDate
        id
        show {
          title
          slug
          images {
            uri
            type
            imageType
            variant {
              slug
            }
          }
        }
      }
    }
  }
`;

const getStation = gql`
  query GetStation($slug: String!) {
    station(slug: $slug) {
      id
      type
      title
      shortTitle
      slug
      media {
        type
        uri
        source
      }
      images {
        uri
        type
        description
        imageType
      }
    }
  }
`;

const onStationEvent = `subscription onStationEvent($profile: String!, $slug: String!) {
    stationEvent(slug: $slug) {
      slug
      id
      title
      playouts(profile: $profile) {
        type
        broadcastDate
        track {
          id
          isrc
          title
          artistName
          images {
            uri
            imageType
            title
          }
        }
      }
    }
}`;

const CurrentTrackQuery = gql`
  query CurrentTrackQuery($stationSlug: String!, $profile: String!) {
    station(slug: $stationSlug) {
      playouts(profile: $profile) {
        track {
          id
          title
          artistName
          images {
            uri
            imageType
            title
          }
        }
      }
    }
  }
`;

export async function fetchCurrentTrack(stationSlug: string): Promise<Track | null> {
  const { data } = await fetchAudioData(CurrentTrackQuery, {
    stationSlug,
    profile: radioProfile,
  });

  if (data?.station && Array.isArray(data.station.playouts) && data.station.playouts.length > 0) {
    const trackData = data.station.playouts[0].track;
    if (trackData) {
      return new Track(trackData);
    }
  }

  return null;
}

export default async function fetchAudioData(
  query: DocumentNode,
  variables: {
    stationSlug?: string;
    startDate?: Date;
    endDate?: Date;
    slug?: string;
    profile?: string;
  }
): Promise<{ data: { station: UniversalApiStation } | null }> {
  const queryParams = new URLSearchParams({
    query: stripIgnoredCharacters(print(query)),
    variables: JSON.stringify(variables),
  });

  const response = await fetch(`${audioApiEndpoint}?${queryParams.toString()}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Api-Key": audioXApiKey,
      Profile: radioProfile,
    },
  });
  const { data, errors } = await response.json();

  if (errors?.length) {
    return { data: null };
  }

  return { data };
}

export async function fetchRadioSchedule(stationSlug?: string): Promise<Schedule[]> {
  if (!stationSlug) return [];

  const startDate = utcToZonedTime(new Date(), TIMEZONE);
  const endDate = addDays(startDate, 7);

  return fetchAudioData(RadioScheduleQuery, {
    stationSlug,
    startDate,
    endDate,
  }).then(({ data }) => {
    if (!data?.station.schedules) return [];
    return data.station.schedules.map((schedule: UniversalApiRadioSchedule) => new Schedule(schedule));
  });
}

export async function fetchStationMetadata(slug: string): Promise<Station | null> {
  return fetchAudioData(getStation, { slug }).then(({ data }) => {
    if (!data?.station) return null;
    return new Station(data.station);
  });
}

function getWebsocketUrl() {
  const header = Buffer.from(
    JSON.stringify({
      host: appsyncHost,
      "x-api-key": appsyncApiKey,
    })
  ).toString("base64");
  const payload = btoa(JSON.stringify({}));
  const url = `wss://${appsyncHost}/graphql/realtime?header=${header}&payload=${payload}&protocol=graphql-ws`;

  return url;
}

export class StationSubscription {
  websocket: WebSocket;
  onTrackEvent: (track: Track) => void;

  constructor(slug: string, onTrackEvent: (track: Track) => void) {
    const url = getWebsocketUrl();
    this.websocket = new WebSocket(url, ["graphql-ws"]);
    this.websocket.onopen = () => {
      this.websocket.send(
        JSON.stringify({
          type: "connection_init",
        })
      );
    };

    this.websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);

      switch (message.type) {
        case "connection_ack":
          this.startSubscription(slug);
          break;
        case "error":
          console.error(message);
          break;
        case "data":
          const trackData = message.payload.data.stationEvent.playouts[0]?.track;

          if (trackData) {
            const track = new Track(trackData);
            onTrackEvent(track);
          }
          break;
      }
    };
  }

  private startSubscription(slug: string) {
    const subscribeMessage = {
      id: window.crypto.randomUUID(),
      type: "start",
      payload: {
        data: JSON.stringify({
          query: onStationEvent,
          variables: { profile: radioProfile, slug },
        }),
        extensions: {
          authorization: {
            "x-api-key": appsyncApiKey,
            host: appsyncHost,
          },
        },
      },
    };
    this.websocket.send(JSON.stringify(subscribeMessage));
  }

  cleanUp() {
    this.websocket.close();
  }
}
