import styled from "@emotion/styled";
import { colors } from "@constants/cssVariables";

const aspectRatios = {
  "16:9": "56.25%",
  "1:1": "100%",
  "3:2": "66.66%",
  "2:3": "150%",
  "4:3": "75%",
};

const AspectRatioContainer = styled("div", { shouldForwardProp: (prop) => prop !== "aspectRatio" })<{
  aspectRatio: "16:9" | "1:1" | "2:3" | "3:2" | "4:3";
}>(({ aspectRatio }) => ({
  width: "100%",
  position: "relative",
  backgroundColor: colors.neutral10,
  paddingBottom: aspectRatios[aspectRatio],
  height: 0,
}));

export default AspectRatioContainer;
