import { useEffect, useState } from "react";
import { AudioMonitor, HeartBeat } from "@4tn/webx-analytics";
import { useQuery } from "@tanstack/react-query";
import { fetchStationMetadata } from "@utils/audio/audioApi";
import { Station } from "@utils/audio/dataModels";
import getHostConfig from "@utils/common/getHostConfig";
import { useNoticeError } from "@utils/common/newRelic";
import * as Styled from "./AudioPlayer.styled";
import audioPlayerManager from "./AudioPlayerManager/AudioPlayerManager";
import IconSection from "./components/IconSection";
import MainPlayerControls from "./components/MainPlayerControls";
import NowPlaying from "./components/NowPlaying";

const heartBeatScheme = [
  { start: 0, interval: 1 * 60 }, // Send heartbeat every minute
];

const AudioPlayer: React.FC = () => {
  const [status, setStatus] = useState<{ state: AudioPlayerState; details: string }>({
    state: "stop",
    details: "",
  });

  const [playerType, setPlayerType] = useState<AudioPlayerEventParams["playerType"]>(null);

  const { mainStationSlug } = getHostConfig();
  const stationQueryKey = ["station", mainStationSlug];

  useEffect(() => {
    const handlePlay = ({ details }: AudioPlayerEventParams["play"]) => {
      HeartBeat.start();
      setStatus({ state: "playing", details });
    };

    const handlePause = ({ details }: AudioPlayerEventParams["pause"]) => {
      HeartBeat.stop();
      setStatus({ state: "pause", details });
    };

    const handleStop = ({ details }: AudioPlayerEventParams["stop"]) => {
      HeartBeat.stop();
      setStatus({ state: "stop", details });
    };

    const handleError = ({ details }: AudioPlayerEventParams["error"]) => {
      HeartBeat.stop();
      setStatus({ state: "failed", details });
    };

    const handlePlayerTypeChange = (playerType: AudioPlayerEventParams["playerType"]) => {
      console.log("audio player type is: ", playerType);
      setPlayerType(playerType);
    };

    const handleAd = ({ details }: AudioPlayerEventParams["adStarted"] | AudioPlayerEventParams["adComplete"]) => {
      setStatus((prevState) => ({ ...prevState, details }));
    };

    const handleAudioSourceChange = ({ source }: { source: string }) => {
      setStatus({ state: "loading", details: "Player is loading" });
      audioPlayerManager.start(source);
    };

    AudioMonitor.init({ id: "audioPlayer", instance: audioPlayerManager });
    HeartBeat.init({
      scheme: heartBeatScheme,
      events: {
        onHeartBeat: (event) => {
          const currentMinute = event.currentHeartBeat / 60;
          const allowedIntervals = [1, 8, 15, 30, 60, 90, 120];
          // Send heartbeat if it's one of the milestones or a multiple of 120 minutes
          if (allowedIntervals.includes(currentMinute) || currentMinute % 120 === 0) {
            return { event_value: `${currentMinute}`, event_label: null };
          }

          return false;
        },
      },
    });

    audioPlayerManager.on("play", handlePlay);
    audioPlayerManager.on("pause", handlePause);
    audioPlayerManager.on("adStarted", handleAd);
    audioPlayerManager.on("adComplete", handleAd);
    audioPlayerManager.on("stop", handleStop);
    audioPlayerManager.on("error", handleError);
    audioPlayerManager.on("playerType", handlePlayerTypeChange);
    window.eventBus.on("audioSourceChange", handleAudioSourceChange);

    return () => {
      audioPlayerManager.off("play", handlePlay);
      audioPlayerManager.off("pause", handlePause);
      audioPlayerManager.off("adStarted", handleAd);
      audioPlayerManager.off("adComplete", handleAd);
      audioPlayerManager.off("stop", handleStop);
      audioPlayerManager.off("error", handleError);
      audioPlayerManager.off("playerType", handlePlayerTypeChange);
      window.eventBus.off("audioSourceChange", handleAudioSourceChange);
      AudioMonitor.unsubscribeFromEvents();
      HeartBeat.stop();
    };
  }, []);

  const { data: station, error } = useQuery<Station | null>({
    queryKey: stationQueryKey,
    queryFn: () => {
      if (!mainStationSlug) return null;
      return fetchStationMetadata(mainStationSlug);
    },
    refetchOnReconnect: false,
  });

  useNoticeError(error, { queryKey: stationQueryKey.join(","), mainStationSlug });

  if (!station) {
    return null;
  }

  return (
    <Styled.AudioPlayerContainer>
      <NowPlaying station={station} playerType={playerType} />
      <MainPlayerControls mountSource={station.mountSource} playbackState={status.state} playerType={playerType} />
      <IconSection />
    </Styled.AudioPlayerContainer>
  );
};

export default AudioPlayer;
